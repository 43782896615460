define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/topic',
    'idis/model/UserInfo',
    'app/damage/integrate/IntegrationDialog',
], function (module, declare, lang, array, topic, UserInfo,
    IntegrationDialog) {
    return declare(module.id.replace(/\//g, '.'), null, {
        // 受け止めるべきsubscriber
        DISABLE_DAMAGE_INTEGRATE: '/app/damage/integrate/DamageReportIntegrator::disable',

        /** @type {IdisMap} */
        idisMap: null,

        /** @type {DamageLayer} */
        damageLayer: null,

        dialog: null,
        canDraw: false,

        constructor: function (idisMap, damageLayer) {
            this.idisMap = idisMap;
            this.damageLayer = damageLayer;

            if (!idisMap.map) {
                console.error('描画用のmapが空');
                return;
            }

            // MainMapで他のDialogが開かれたらSubでそれを感知して自分自身を閉じる
            topic.subscribe(this.DISABLE_DAMAGE_INTEGRATE, lang.hitch(this, function () {
                this.deactivate();
            }));

            // 範囲選択された後のaction
            this.idisMap.map.on('feature-select-result', lang.hitch(this, function (event) {
                this.onSelected(event);
            }));
        },

        activate: function () {
            this.idisMap.featureSelect(this.damageLayer.currentFeatures());
        },

        deactivate: function () {
            // 地図画面の「被害報告統合」ボタンの色を変更する
            topic.publish(module.id + '::deactivated');

            if (this.dialog) {
                this.dialog.hide();
            }
        },

        destroy: function () {
            this.dialog.destroy();
        },

        onSelected: function (event) {
            // 地図画面の「被害報告統合」ボタンの色を変更する
            topic.publish(module.id + '::deactivated');

            var features = event.result;

            // 候補一覧でダイアログを作る
            // 0件/1件しかなかったら親子関係が作れないので何もしない
            if (!features || features.length <= 1) {
                console.error('統合対象が2件未満なので統合できない');
                return;
            }

            // 異なる市町の被害を含む場合は失敗とする
            var municipalityCd = '';
            var multiMunicsFlg = false;
            array.forEach(features, function (feature) {
                if (municipalityCd && municipalityCd !== feature.properties.municipalityCd) {
                    multiMunicsFlg = true;
                    return;
                }
                municipalityCd = feature.properties.municipalityCd;
            });
            if (multiMunicsFlg) {
                console.error('異なる市町村の被害を含むので統合できない');
                return;
            }
            // ユーザの管理対象市町でない場合は失敗とする
            var userMunicipalityCds = UserInfo.getMunicipalityCds();
            var userMunicFlg = false;
            array.forEach(userMunicipalityCds, function (userMunicipalityCd) {
                if (userMunicipalityCd === municipalityCd) {
                    userMunicFlg = true;
                    return;
                }
            });
            if (!userMunicFlg) {
                console.error('ユーザの管理対象市町村でないので統合できない');
                return;
            }

            // 初回呼び出し時にインスタンス生成
            if (!this.dialog) {
                this.dialog = new IntegrationDialog();
            }

            this.dialog.show();

            // ダイアログにはfeature.propertiesだけ渡す
            var properties = features.map(function(feature) {
                return feature.properties;
            });

            this.dialog.setData(properties);
        }
    });
});
