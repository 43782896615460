/**
 * 混雑状況
 * @module app/shelter/consts/CrowdedStatus
 */
define( [
], function() {
    var _CROWDED_STATUS = {
        'UNDER_CAPACITY': {'value':'1', 'label':'空き'},
        'CROWDED':        {'value':'2', 'label':'混雑'},
        'OVER_CAPACITY':  {'value':'3', 'label':'定員一杯'},
        'UNKNOWN':        {'value':'9', 'label':'不明'}
    };
    var _CHANGE_MESSAGE = '避難者数から自動算出した混雑状況をセットしました。<br>' +
            '手動で混雑状況を変更する必要がある場合、内容を確認してください。';

    /**
     * 混雑状況を算出する
     */
    function _calcCrowdedStatus(evaqueeNum, capacity) {
        if (isNaN(evaqueeNum) || !evaqueeNum) {
            evaqueeNum = 0;
        }
        if (!capacity || Number(capacity) === 0) {
            return _CROWDED_STATUS.UNKNOWN.value;
        }
        var percent =  Number(evaqueeNum) / Number(capacity) * 100;
        if (percent >= 100) {
            return _CROWDED_STATUS.OVER_CAPACITY.value;
        } else if  (percent >= 50) {
            return _CROWDED_STATUS.CROWDED.value;
        } else if  (percent >= 0) {
            return _CROWDED_STATUS.UNDER_CAPACITY.value;
        }
        return _CROWDED_STATUS.UNKNOWN.value;
    }

    /** 名称を取得する */
    function _getLabel(value) {
        var propNames = Object.getOwnPropertyNames(_CROWDED_STATUS);
        var label = null;
        for (var i in propNames) {
            if (value === _CROWDED_STATUS[propNames[i]].value) {
                label = _CROWDED_STATUS[propNames[i]].label;
            }
        }
        return label;
    }

    return {
        options: Object.values(_CROWDED_STATUS),  // selectorのoptions
        changeMessage: _CHANGE_MESSAGE,           // 自動セット時のメッセージ
        calcCrowdedStatus: _calcCrowdedStatus,
        getLabel: _getLabel
    };
});