import { GeoJsonClusterLayer, GeoJsonLayer } from "idismap";

/**
* 被害情報を表示するためのレイヤー
*
* 被害情報は種別や市町村でレイヤーが分かれていて, それらを統合的に表示するもの.
*/
define([
    'dojo/_base/declare',
    'dojo/_base/lang',
    'app/model/DisasterInfo'
], function (declare, lang, DisasterInfo) {
    'use strict';

    /**
     * 複数レイヤーのGeoJsonを1つにまとめる
     *
     * @param {object} geoJsonStore
     */
    function mergeGeoJson(geoJsonStore) {
        var features = [];
        Object.keys(geoJsonStore).forEach(function (store) {
            features.push(geoJsonStore[store].features);
        });

        return {
            "type": "FeatureCollection",
            "features": features.flat()
        };
    }

    function isDamageReport(feature) {
        // 判断基準はこれだけでいいのかな。。
        return (feature &&
            feature.properties &&
            feature.properties.disasterId &&
            feature.properties.disasterId.toString() === DisasterInfo.getDisasterId() &&
            feature.properties.damageReportId);
    }

    /**
     * データ加工
     *
     * idismapに渡す前のデータ前処理
     * @param {object} geojson
     */
    function manipulate(geojson) {
        geojson.features.forEach(function (feature) {
            var arrayed = feature.properties.damageTypeList.split(',');
            feature.properties.damageTypeList = arrayed;
        });

        return geojson;
    }

    return declare(null, {
        /** 被害ポイント用のレイヤーとデータ */
        damage: {
            layer: null, // idismap.GeoJsonClusterLayer, 複数レイヤーにわたる被害ポイントを統合して1レイヤーにまとめる
            geoJsonStore: {}, // 各レイヤーのgeojsonを入れておく置き場
            mergedGeoJson: {}, // 表示するgeojsonデータ, geoJsonStoreから都度生成する
            isAdded: false // 地図にaddされているか, の状態
        },

        /** 作図データ用のレイヤーとデータ */
        draw: {
            layerStore: {}, // 1レイヤーあたり1つの作図レイヤーを作る
        },

        /**
         * 被害レイヤーを一つ追加して, 地図に表示
         * @param {Object} layerInfo 被害レイヤーのlayer.json情報
         * @param {Object} damageData 被害ポイントのデータ
         * @param {Object} damageStyle 被害ポイントのスタイル
         * @param {Object} drawData 作図データ
         * @param {Object} drawStyle 作図データのスタイル
         */
        add: function (layerInfo, damageData, damageStyle, drawData, drawStyle) {
            // データ前処理
            var manipulatedData = manipulate(damageData);

            // 被害ポイント
            this.damage.geoJsonStore[layerInfo.id] = manipulatedData;
            if (!this.damage.layer) {
                var parameter = {
                    geojson: mergeGeoJson(this.damage.geoJsonStore),
                    minZoom: layerInfo.minZoom,
                    maxZoom: layerInfo.maxZoom,
                    styleJs: damageStyle,
                };

                this.damage.layer = new GeoJsonClusterLayer(layerInfo.id, parameter);
            }

            // 作図データ
            if (this.draw.layerStore[layerInfo.id]) {
                // 本当は下のremove()で消したいが, その後の処理でlayerStore[layerInfo.id]を上書きしてしまうのでここで削除する
                // TODO: なんとかならないか
                this.draw.layerStore[layerInfo.id].remove();
            }

            // 作図データは存在しないことがあるので存在チェックする
            if (drawData && drawStyle) {
                var parameter = {
                    geojson: drawData,
                    minZoom: layerInfo.minZoom,
                    maxZoom: layerInfo.maxZoom,
                    styleJs: drawStyle,
                };

                this.draw.layerStore[layerInfo.id] = new GeoJsonLayer(layerInfo.id, this.damage.parameter);
            }

            // LayerControl.addLayer/LayerControl.removeLayerから操作できるようにfunctionが入ったオブジェクトを返す
            return {
                addTo: lang.hitch(this, function (map) {
                    // 被害ポイントレイヤー
                    if (!this.damage.isAdded) {
                        // まだ地図に載っていなかったらaddTo
                        this.damage.layer.addTo(map);
                        this.damage.isAdded = true;
                    } else {
                        // 地図に載っていたらデータ更新
                        this.damage.layer.updateData(mergeGeoJson(this.damage.geoJsonStore));
                    }

                    // 作図データはIDごとにレイヤーがあるので毎回addTo
                    if (this.draw.layerStore[layerInfo.id]) {
                        this.draw.layerStore[layerInfo.id].addTo(map);
                    }
                }),
                remove: lang.hitch(this, function () {
                    if (this.damage.layer && this.damage.geoJsonStore[layerInfo.id]) {
                        delete this.damage.geoJsonStore[layerInfo.id];
                        this.damage.layer.updateData(mergeGeoJson(this.damage.geoJsonStore));
                        this.damage.layer.closePopup();
                    }

                    if (this.draw.layerStore[layerInfo.id]) {
                        this.draw.layerStore[layerInfo.id].remove();
                    }
                })
            };
        },

        /**
         * 被害統合(ポリゴンからその中のpointを検索)機能のために被害ポイント一覧を返す
         */
        currentFeatures: function() {
            var features = mergeGeoJson(this.damage.geoJsonStore).features;

            var damageIds = [];
            return features.filter(lang.hitch(this, function (feature) {
                if (!isDamageReport(feature)) {
                    return false;
                }

                // 被害情報に複数の被害種別が設定されている場合への対応
                // 被害情報が複数のレイヤーに紐づくことにより同じ被害が複数回処理されてしまうことを回避するため、
                // すでに処理した被害情報IDであれば候補に含まれないようにする
                if (damageIds.indexOf(feature.properties.damageReportId) !== -1) {
                    return false;
                }

                damageIds.push(feature.properties.damageReportId);
                return true;
            }));
        }
    });
});
