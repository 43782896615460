import { GeoJsonLayer } from 'idismap';
import { circle } from '@turf/turf';

/**
 * 台風予想図を表示するためのレイヤー
 */
define([
    'dojo/_base/declare', 'dojo/_base/lang'
], function (declare, lang) {
    'use strict';

    /**
     * layerInfoの情報からレイヤーオプションを作る
     *
     * minZoom/maxZoomが入っていたらそれを入れる.
     *
     * 将来 Idismap.GeoJsonLayer.GeoJsonLayerParameterのプロパティが増えて,
     * そのプロパティがlayerInfoの情報を使うときはここに足す.
     * @param {GeoJSON} geojson
     * @param {object} layerInfo
     * @param {object} styleJs
     * @returns レイヤーオプションのオブジェクト
     */
    function generateLayerOption(geojson, layerInfo, styleJs) {
        var result = {
            geojson: geojson,
            styleJs: styleJs
        };

        // layerInfoに入っているオプションを適用
        ['minZoom', 'maxZoom'].forEach(function (prop) {
            if (layerInfo[prop]) {
                result[prop] = layerInfo[prop];
            }
        });

        return result;
    }

    /**
     * 中心点からの円を表すfeatureを作る
     *
     * - 円自身を表すPolygon
     * - 園の周辺を表すLine
     * を作る
     *
     * "円"を表すpolygonは存在しないので,turfからcircleっぽいpolygonを作る機能を呼び出す
     * circleっぽい=正64角形
     *
     * @param {GeoJSON.feature} feature
     * @param {string} keyName 円の半径を表すプロパティ名
     */
     function generateCircle(feature, keyName) {
        if (!feature.properties || !feature.properties[keyName]) {
            return [];
        }

        var fill = circle(
            feature.geometry.coordinates,
            feature.properties[keyName],
            {
                'units': 'kilometers',
                'properties': Object.assign({}, feature.properties, {
                    'type': 'circleFill-' + keyName
                })
            }
        );

        var outline = {
            'type': 'Feature',
            'geometry': {
                'type': 'LineString',
                'coordinates': fill.geometry.coordinates[0].slice()
            },
            'properties': Object.assign({}, feature.properties, {
                'type': 'circleOutline-' + keyName
            })
        };

        return [ fill, outline ];
    }

    /**
     * 台風情報XMLから生成したgeojsonからいくつか地物を追加する
     *
     * - 予想経路を表すLine
     * - 強風/暴風域を表すpolygon
     */
    function manipulateGeoJSON(geojson) {
        var featuresToAdd = [];

        // 現況/予想の暴風域を表すpolygon(fill/outline)
        geojson.features.forEach(function(feature) {
            generateCircle(feature, 'stormKm').forEach(
                function(circleFeature) {
                    featuresToAdd.push(circleFeature);
                }
            );
        });

        // 現況の強風域を表すpolygon(fill/outline)
        geojson.features.forEach(function(feature) {
            generateCircle(feature, 'strongWindKm').forEach(
                function(circleFeature) {
                    featuresToAdd.push(circleFeature);
                }
            );
        });

        // 予想経路を表すline
        featuresToAdd.push({
            'type': 'Feature',
            'geometry': {
                'type': 'LineString',
                'coordinates': geojson.features.map(function(feature) {
                    return feature.geometry.coordinates;
                })
            },
            'properties': {
                'type': 'forcastPath'
            }
        });

        featuresToAdd.forEach(
            function(feature) {
                geojson.features.push(feature);
            }
        );

        return geojson;
    }

    /** 本体 */
    return declare(null, {
        /** @type {import("idismap").GeoJsonLayer} */
        layer: null,

        /**
         * constructor
         * @param {GeoJSON} geojson
         * @param {object} layerInfo
         * @param {object} styleJs style.jsの内容
         */
        constructor: function (geojson, layerInfo, styleJs) {
            this.layer = new GeoJsonLayer(
                layerInfo.id,
                generateLayerOption(
                    manipulateGeoJSON(geojson),
                    layerInfo,
                    styleJs
                )
            );
        }
    });
});
