define([
    'module',
    'dojo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DistrictSelectDialog.html',
    'idis/service/Requester',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    '../config',
    'dijit',
    'dijit/tree/ObjectStoreModel',
    'idis/view/tree/CheckTree',
    // 以下、変数で受けないモジュール
    'dojo/store/Memory',
    'idis/view/form/Button',
], function (module, dojo, array, declare, lang, template, Requester, _IdisWidgetBase, 
    DialogChain, IdisDialog, config, dijit, ObjectStoreModel, CheckTree
) {
    var content = declare(_IdisWidgetBase, {
        templateString: template,
        baseClass: 'districtSelectDialog-Container',
        widgetsInTemplate: true
    });

    var container = declare(module.id.replace(/\//g, '.'), [IdisDialog], {
        // Tree
        _districtTree: null,

        constructor: function (options) {
            lang.mixin(this, options);
            this.title = '行政区域';
            this.inherited(arguments);
            this.inner = new content();
            this.chain = DialogChain.get(this);
            this.own(this.inner);
        },

        // override
        postCreate: function () {
            this.inherited(arguments);
            this.set('content', this.inner);
            this._addEventHandlers();
        },

        /*---------- 表示制御系 ----------*/

        /**
         * 処理名：地区選択ダイアログ表示。
         * 処理概要：地区選択ダイアログを表示する。
         *
         * @param なし
         * @return なし
         */
        show: function () {
            this.inherited(arguments);

            // Tree階層取得、生成
            if (!this._districtTree) {
                this.createDistrictSelectTree();
            }

            // ダイアログの位置を強制的に変更
            dojo.style(this.domNode, 'left', '700px');
            dojo.style(this.domNode, 'top', '200px');
            dojo.style(this.domNode, 'height', '');
        },

        /**
         * 処理名：地区選択ダイアログ非表示。
         * 処理概要：地区選択ダイアログを非表示にする。
         *
         * @param なし
         * @return なし
         */
        _addEventHandlers: function () {
            if (dijit.byId('DistrictSelectDialog-DLDeselectButton')) {
                dijit.byId('DistrictSelectDialog-DLDeselectButton').on(
                    'click',
                    lang.hitch(this, function () {
                        this.allDeselect();
                    })
                );
            }
        },

        /**
         * 処理名：Tree生成。
         * 処理概要：Treeを生成する。
         *
         * @return なしCreate
         */
        createDistrictSelectTree: function () {
            if (this._districtTree !== null) {
                // ツリーデータ破棄
                if (this._districtTree.model !== null) {
                    this._districtTree.model.destroy();
                    delete this._districtTree.model;
                }
                this._districtTree.destroy();
                this._districtTree = null;
            }

            // 全都道府県・市区町村jsonファイル取得
            Requester.get('../../../data/master/draw/allCity.json').then(
                lang.hitch(this, function (treeData) {
                    var prefCd = config.municInfo.prefMunicCd.substr(0,2);
                    var prefData = [];
                    treeData.forEach(lang.hitch(this, function(node) {
                        if (node.id && (node.id === 'root' || node.id.substr(0,2) === prefCd)) {
                            prefData.push(node);
                        }
                    }));
                    // storeの作成
                    var store = new dojo.store.Memory({
                        data: prefData,
                        getChildren: function (object) {
                            return this.query({ parent: object.id });
                        },
                        getParentIdentity: function (object) {
                            var result = null;
                            var parent = this.query({ id: object.parent });
                            if (parent[0] && parent[0].id) {
                                result = parent[0].id;
                            }
                            return result;
                        }
                    });

                    // modelの作成
                    var model = new ObjectStoreModel({
                        store: store,
                        query: { id: 'root' },
                        mayHaveChildren: function (item) {
                            var hasChildren = store.getChildren(item).length === 0 ? false : true;
                            return hasChildren;
                        }
                    });

                    // treeの作成
                    this._districtTree = new CheckTree({
                        style: 'height: 420px',
                        model: model,
                        showRoot: false,
                        openOnClick: false,
                        branchCheckBox: true,
                        leafCheckBox: true,
                        onClick: this.selectTree4Label
                    });
                    this._districtTree.placeAt(dojo.byId('DistrictSelectDialog-DLTree'));
                    this._districtTree.startup();
                    this._districtTree.on(
                        'check-box-click',
                        lang.hitch(this, function (treeEvt) {
                            this.selectTree4CheckBox(treeEvt.item);
                        })
                    );
                })
            );
        },

        /**
         * 処理名：Treeのチェックボックス選択。
         * 処理概要：Treeのチェックボックスをクリックした時の処理。
         *
         * @param item 選択した情報
         * @return なし
         */
        selectTree4CheckBox: function (item) {
            var check = this._districtTree.isChecked(item);
            var list = [];

            list.push(item.id);

            var self = this;
            var children = this._districtTree.model.store.getChildren(item);
            array.forEach(children, function (childItem) {
                list.push(childItem.id);

                if (childItem.designated === '1') {
                    // 政令指定都市の場合
                    var designatedChildren = self._districtTree.model.store.getChildren(childItem);
                    array.forEach(designatedChildren, function (childchildItem) {
                        list.push(childchildItem.id);
                    });
                }
            });
            

            if (check) {
                var dataForDraw = [];
                Requester.get('../../../data/master/draw/' + list[0].substring(0,2) + '.geojson').then(
                    lang.hitch(this, function (data) {
                        data.features.forEach(function(feature) {
                            if (list.includes(feature.properties.dcode)) {
                                feature.properties.isAdministrativeDivision = true;
                                feature.properties.administrativeCode = feature.properties.dcode;
                                feature.properties._markerType = 'polygon';
                                feature.properties['_idismap_draw'] = 'default';
                                dataForDraw.push(feature);
                            }
                        });

                    this.emit('district-on-draw', { dataForDraw: {
                        features: dataForDraw,
                        type: "FeatureCollection"
                    } });
                }));
            } else {
                this.emit('district-on-delete', { layers: list});
            }
        },

        hide: function () {
            this.inherited(arguments);
            this.emit('district-dialog-on-hide');
        },

        /**
         * 処理名：全選択解除。
         * 処理概要：全ての選択を解除する。
         *
         * @param なし
         * @return なし
         */
        allDeselect: function () {
            //選択済みの地区を非選択に
            var selectedList = this._districtTree.getCheckedLeafs();
            var list = [];

            selectedList.forEach(function (item) {
                list.push(item.id);
            });

            this.emit('district-on-dist-change', { layers: list, textBox: false });

            //ツリーのリセット
            this.createDistrictSelectTree();
        },

        /**
         * 被害情報ツリー要素クリック時の動作。
         * @param {Object} item 選択したツリー要素
         * @param {string} [item.dCategoryCode] 情報種別
         * @returns None
         */
        selectTree4Label: function (item, treeNode, evt) {
            var arg = arguments;
            if (arg.callee.caller.nom !== '_onCheckBoxClick') {
                this.setChecked(item, !this.isChecked(item));
                this.emit('check-box-click', {item});
            }
        }
    });

    return container;
});